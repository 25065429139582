import React from "react";
import HeaderMenuBS from "../containers/App/HeaderMenuBS";
import GlobalStyle from "../containers/App/Common/global-styles";
import "../components/layout.css";
import Fonts from "../containers/App/Common/fonts"

// This is dumb
export default function Privacy() {
  return (
    <div
      style={{
        margin: `5.5rem 7.5vw 0 7.5vw`,
        backgroud: '#f9fafc',
        color: '#0b1d27',
      }}
    >
      <Fonts />
      <GlobalStyle />
      <HeaderMenuBS/>
      <main>
        <br/>
        <h1>Privacy policy</h1> 
        <p>
          Museli is committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your Personal Information.
          We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information.
          A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information Commissioner at <a HREF="https://www.oaic.gov.au/">www.aoic.gov.au</a>
        </p>
        <p>
          If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our <a HREF="https://museli.app/terms/">Terms and Conditions</a>, unless otherwise defined in this Privacy Policy.
        </p>

        <h2>Information Collection and Use</h2>
        <p>
          To enabled application features and provide a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to first name, email address, IP address, identifiers such as user ID and device ID, purchase history and usage statistics. Additionally, user submitted video or audio recordings, and feedback or support requests, may also be collected to enable certain features, improve functionality, and respond to support cases.  The information that we request will be retained by us and used as described in this privacy policy.
          The app does use third-party services, such as social network authentication providers, that may collect information used to identify you.
        </p>

        

        {/* <h2>Log Data</h2>
        <p>
          We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
        </p> */}

        <h2>Cookies</h2>
        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
        <p>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>

        <h2>Third Parties</h2>
        <p>
          We may employ third-party companies and individuals due to the following reasons:
          <br/><br/>•	To facilitate our Service;
          <br/>•	To provide the Service on our behalf;
          <br/>•	To perform Service-related services; or
          <br/>•	To assist us in analyzing how our Service is used.
          <br/><br/>We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
        </p>
        <p>
          Data we collect from third parties:
          <br/><br/>•	If you choose to sign in with a third party account (for example your google account), we receive information from the third-party account provider, such as name, email address, profile picture, and may retrieve contacts information to connect you with your friends who already use our Services);
          <br/>•	Data from platforms that the applications run on (e.g. to verify payments);
          <br/>•	If you make purchases in our Services, we retrieve data relating to the transactions from the involved third-party payment service providers to validate those purchases;
        </p>
        <p>Links to the privacy policy of third-party service providers used by the app:</p>
        <ul>
          <li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase</a></li>
          <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google</a></li>
          <li><a href="https://www.apple.com/legal/privacy/data/en/sign-in-with-apple/" target="_blank" rel="noopener noreferrer">Apple</a></li>
          {/* <li><a href="https://www.facebook.com/about/privacy/update/printable/" target="_blank" rel="noopener noreferrer">Facebook</a></li> */}
          <li><a href="https://www.revenuecat.com/privacy/" target="_blank" rel="noopener noreferrer">RevenueCat</a></li>
        </ul>

        <h3>YouTube API Integration</h3>
        <p>Our app uses the YouTube API to display video content within the app. In accordance with YouTube's API Services Developer Policies, we are required to disclose the following information in our privacy policy:</p>
        <ul>
            <li>
                <strong>Google Privacy Policy:</strong> <p>By using the YouTube API, our app integrates with services provided by Google LLC ("Google"). As a result, your use of the YouTube features within our app is subject to Google's Privacy Policy, which can be found at <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>.</p>
            </li>
            <li>
                <strong>Data Collection and Usage:</strong> <p>When you interact with the YouTube video player within our app, we may collect certain usage data, such as video watch progress and user preferences, to enhance your experience and provide personalized features. This data is used solely for the purpose of providing and improving the app's functionality and is not shared with third parties, except as required by law or with your explicit consent.</p>
            </li>
            <li>
                <strong>Data Retention:</strong> <p>We store the collected usage data only for as long as necessary to provide the features and functionality of our app. Once the data is no longer required, we will securely delete or anonymize it to ensure your privacy is protected.</p>
            </li>
            <li>
                <strong>YouTube API Restrictions:</strong> <p>Please note that the YouTube API features within our app are subject to the YouTube Terms of Service (<a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">https://www.youtube.com/t/terms</a>), the YouTube API Services Terms of Service (<a href="https://developers.google.com/youtube/terms/api-services-terms-of-service" target="_blank" rel="noopener noreferrer">https://developers.google.com/youtube/terms/api-services-terms-of-service</a>) and Google's API Services User Data Policy (<a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer">https://developers.google.com/terms/api-services-user-data-policy</a>). By using the YouTube API features in our app, you agree to comply with these terms and policies.</p>
            </li>
        </ul>


        <h2>Disclosure of Personal Information</h2>
        <p>
          Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from unauthorized access, modification or disclosure.
          When your Personal Information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your Personal Information. However, most of the Personal Information is or will be stored in client files which will be kept by us for a minimum of 7 years.
        </p>

        <h2>Security</h2>
        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

        <h2>Access to your Personal Information</h2>
        <p>
          You may access the Personal Information we hold about you and to update and/or correct it, subject to certain exceptions. If you wish to access your Personal Information, please contact us in writing.
          Museli will not charge any fee for your access request, but may charge an administrative fee for providing a copy of your Personal Information.
          In order to protect your Personal Information we may require identification from you before releasing the requested information. You may request a copy of this information, or deletion of this information by submitting a request to compliance@museli.app.
        </p>

        <h2>Children’s Privacy</h2>
        <p>
          These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.
        </p>

        <h2>Links to Other Sites</h2>
        <p>
          This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </p>

        <h2>Maintaining the Quality of your Personal Information</h2>
        <p>
          It is an important to us that your Personal Information is up to date. We  will  take reasonable steps to make sure that your Personal Information is accurate, complete and up-to-date. If you find that the information we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue to provide quality services to you.
        </p>

        <h2>Policy Updates</h2>
        <p>
          This Policy may change from time to time and is available on our website.
        </p>

        <h2>Privacy Policy Complaints and Enquiries</h2>
        <p>
          If you have any queries or complaints about our privacy policy please contact us at: compliance@museli.app
        <br/>Last updated 14th May 2024
        </p>
        </main>
    </div>
  )
}